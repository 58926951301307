
import React from 'react'
import { PhotoGallery } from '../components/PhotoGallery'


// const photos = [
//     "https://i.ibb.co/GFz8LSb/DSC-3558-Poprawione-Szum.jpg",
//     "https://i.ibb.co/zrTmfQy/DSC-3801-Poprawione-Szum.jpg",
//     "https://i.ibb.co/2MqyxL7/DSC-3938-Poprawione-Szum.jpg",
//     "https://i.ibb.co/4MBYjTC/DSC-5323-Poprawione-Szum.jpg",
//     "https://i.ibb.co/sgbX4HZ/DSC-5489-Poprawione-Szum.jpg",
//     "https://i.ibb.co/WzBxfZx/DSC-6243-Poprawione-Szum.jpg",
//     "https://i.ibb.co/FB2Fn1D/DSC-8707-Poprawione-Szum.jpg",
//     "https://i.ibb.co/474bzR0/DSC-9522-Poprawione-Szum.jpg",
//     "https://i.ibb.co/tQ8ftPR/DSC-9630-Poprawione-Szum.jpg",
//     "https://i.ibb.co/yX4VLqQ/DSC-9968-Poprawione-Szum.jpg"
// ]

const photos = [
    "https://i.ibb.co/Tb3dQpB/DSC-3558-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/VqN7rNp/DSC-3801-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/FmVZPgH/DSC-3938-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/ct8Y5Yw/DSC-5323-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/NrGLYDR/DSC-5489-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/KbTjsGR/DSC-6243-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/mR8FHtk/DSC-8707-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/99LrYwC/DSC-9522-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/fQ2j9DK/DSC-9630-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/JRQ5PRn/DSC-9968-Poprawione-Szum-min.jpg"
]
export const Klub = () => {
    return (
        <PhotoGallery photos={photos} />
    )
}
