import React from 'react'
import "./Contact.scss";
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
export const Contact = () => {
  return (
    <div className="contact">
      <div className="contact__container">
        <h1>Zapraszam do kontaktu!</h1>

        <div className="contact__info">
          <p> Marzena Podleżyńska</p>
          <p><strong>Numer telefonu:</strong> 601 057 975</p>
          <p><strong>Email:</strong> marzena.podlezynska@wp.pl</p>
        </div>

        <div className="contact__links">
          <a href="https://www.facebook.com/profile.php?id=61566184799147" target="_blank" rel="noopener noreferrer">
            <FaFacebookF /> Marzena Podleżyńska fotografia
          </a>
          <a href="https://www.facebook.com/marzena.podlezynska" target="_blank" rel="noopener noreferrer">
            <FaFacebookF /> Marzena Podleżyńska
          </a>
          <a href="https://www.instagram.com/m.podlezynska/" target="_blank" rel="noopener noreferrer">
            <FaInstagram /> m.podlezynska
          </a>
        </div>
      </div>
    </div>
  )
}
