import React from 'react'
import "./Portfolio.scss"
import { Link } from 'react-router-dom'
// const photos = [
//     "https://i.ibb.co/9bFtF2B/DJI-20240529220529-0058-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/3f1Mjnt/DSC-1585-Poprawione-Szum.jpg",
//     "https://i.ibb.co/9N9TZn3/DSC-6838-Poprawione-Szum.jpg",
//     "https://i.ibb.co/hKT0gJ0/DSC-7314-Poprawione-Szum.jpg"
// ]
const photos = [
    "https://i.ibb.co/894KmfS/DJI-20240529220529-0058-D-Poprawione-Szum.jpg",
    "https://i.ibb.co/pyL1tX0/DSC-1585-Poprawione-Szum.jpg",
    "https://i.ibb.co/5hJsxPs/DSC-6838-Poprawione-Szum.jpg",
    "https://i.ibb.co/YtD31M8/DSC-7314-Poprawione-Szum.jpg"

]
export const Portfolio = () => {
    return (
        <div className='portfolio'>
            <div className='wrap'>


                <div className="category dron">
                    <Link to="/dron">
                        <img src={photos[0]} alt="" />
                        <div className="label">
                            <p>/Dron</p>
                            <div className="shape"></div>
                        </div>

                    </Link>
                </div>




                <div className="category plener">
                    <Link to="/plener">
                        <img src={photos[2]} alt="" />
                        <div className="label">
                            <p>/Plener</p>
                            <div className="shape"></div>
                        </div>
                    </Link>
                </div>



                <div className="category klub">
                    <Link to="/klub">
                        <img src={photos[1]} alt="" />
                        <div className="label">
                            <p>/Klub</p>
                            <div className="shape"></div>
                        </div>
                    </Link>
                </div>



                <div className="category portret">
                    <Link to="/portret">
                        <img src={photos[3]} alt="" />
                        <div className="label">
                            <p>/Portret</p>
                            <div className="shape"></div>
                        </div>
                    </Link>
                </div>

            </div>
        </div>
    )
}
