import { Home } from "./pages/Home.js"

//zakladki
import { Portfolio } from "./pages/Portfolio.js"
import { Contact } from "./pages/Contact.js"
import { AboutMe } from "./pages/AboutMe.js"
import { Navbar } from "./components/Navbar.js";
//galerie
import { Plener } from "./pages/Plener.js";
import { Dron } from "./pages/Dron.js";
import { Klub } from "./pages/Klub.js";
import { Portret } from "./pages/Portret.js";
import logo from './logo.svg';
import './App.scss';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const photo = "https://i.ibb.co/nb0JmwT/8.jpg";

function App() {

  return (

    <div className="app" >

      <BrowserRouter>
        <div className="contener" style={{ backgroundImage: `url(${photo})` }}>
          <div className="blackout">
            <Navbar />
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/portfolio' element={<Portfolio />} />
              <Route path='/aboutme' element={<AboutMe />} />
              <Route path='/dron' element={<Dron />} />
              <Route path='/plener' element={<Plener />} />
              <Route path='/portret' element={<Portret />} />
              <Route path='/klub' element={<Klub />} />
              <Route path='/contact' element={<Contact />} />
            </Routes >
          </div>
        </div>
      </BrowserRouter>

    </div>

  );
}

export default App;
