import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import "./Navbar.scss"
import logo from '../logo.svg';
// const photos = [


//     "https://i.ibb.co/dBtfYd0/7.jpg",

//     "https://i.ibb.co/nb0JmwT/8.jpg",
//     "https://i.ibb.co/nQvd5C6/6.jpg",


// ]
const photos = [

    "https://i.ibb.co/SPGcYDP/7-min.jpg",
    "https://i.ibb.co/QrRj0mg/8-min.jpg",
    "https://i.ibb.co/6yDxBJZ/9-min.jpg"

]
export const Navbar = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const location = useLocation();
    const isHomePage = location.pathname === '/'
    const isPortfolio = location.pathname === '/portfolio'
    const isContact = location.pathname === '/contact'
    const isAboutMe = location.pathname === '/aboutme'
    const intervalRef = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);


    useEffect(() => {
        if (isHomePage) {
            startImageRotation();
        } else {
            setCurrentImageIndex(1);
        }

        return () => clearInterval(intervalRef.current);
    }, [isHomePage]);

    const startImageRotation = () => {
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
            setCurrentImageIndex((prevIndex) =>
                prevIndex === photos.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000);
    };

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
        startImageRotation();
    };
    return (
        <div className={

            (isHomePage ? 'Navbar-home' : 'Navbar-subPage')
        } style={isPortfolio || isAboutMe || isContact ? { backgroundColor: "transparent" } : { backgroundImage: `url(${photos[currentImageIndex]})` }}>
            <div className="wrap">
                <nav  >

                    <img id="logo" src={logo} alt="logo" />



                    <ul className={menuOpen ? 'open' : ''}>
                        <li><Link onClick={() => setMenuOpen(false)} to="/">Strona główna</Link></li>
                        <li><Link onClick={() => setMenuOpen(false)} to="/portfolio">Portfolio</Link></li>
                        <li><Link onClick={() => setMenuOpen(false)} to="/aboutme">O mnie</Link></li>
                        <li><Link onClick={() => setMenuOpen(false)} to="/contact">Kontakt</Link></li>
                    </ul>

                    <div className="hamburger" onClick={() => setMenuOpen(!menuOpen)}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>

                </nav>
                {isHomePage && <div className="mainPage">
                    <div className="text">
                        <h5>Marzena Podleżyńska</h5>
                        <h3>Portfolio fotograficzne</h3>
                        <h6>Jedno z moich ulubionych zdjęć to te, które zrobię jutro.</h6>

                    </div>

                    <div className='photoMenu'>
                        <img src={photos[0]} alt="grafikaGlowna1" onClick={() => handleThumbnailClick(0)} />
                        <img src={photos[2]} alt="grafikaGlowna2" onClick={() => handleThumbnailClick(2)} />
                        <img src={photos[1]} alt="grafikaGlowna3" onClick={() => handleThumbnailClick(1)} />

                    </div>
                </div>}




            </div>
        </div>
    )
}
