
import { PhotoGallery } from '../components/PhotoGallery'
import React from 'react'

// const photos = [
//     "https://i.ibb.co/4ZxjrX1/DJI-20240502203135-0004-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/9Yg2bCD/DJI-20240527203702-0020-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/28WzYsN/DJI-20240529221803-0073-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/P95Rxkb/DJI-20240805134324-0016-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/DKzymmR/DJI-20240807053115-0101-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/X85vxRB/DJI-20240807224336-0268-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/gzX45dP/DJI-20240808220513-0326-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/fMk9Dmf/DJI-20240813225041-0492-D.jpg"
// ]
const photos = [
    "https://i.ibb.co/D4596vw/DJI-20240502203135-0004-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/yy9FkQg/DJI-20240527203702-0020-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/1rcT81s/DJI-20240529221803-0073-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/dPcSgm6/DJI-20240805134324-0016-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/jhfRxSk/DJI-20240807053115-0101-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/9qzkf7k/DJI-20240807224336-0268-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/XLjJh7j/DJI-20240808220513-0326-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/Mf2hnG9/DJI-20240813225041-0492-D-min.jpg"
]
export const Dron = () => {
    return (
        <PhotoGallery photos={photos} />
    )
}
