
import React from 'react'
import { PhotoGallery } from "../components/PhotoGallery"
// const photos = [
//     "https://i.ibb.co/s2hnBwt/DJI-20240530124758-0121-D-Poprawione-Szum.jpg",
//     "https://i.ibb.co/HnJCMJ6/DSC-0838-Poprawione-Szum.jpg",
//     "https://i.ibb.co/fdNF13k/DSC-6147-Poprawione-Szum.jpg",
//     "https://i.ibb.co/VT8QMX9/DSC-6660-Poprawione-Szum.jpg",
//     "https://i.ibb.co/VHqJgNy/DSC-6693-Poprawione-Szum.jpg",
//     "https://i.ibb.co/1ryRHxX/DSC-7452-Poprawione-Szum.jpg",
//     "https://i.ibb.co/946Zbnj/DSC-9388-Poprawione-Szum.jpg",
//     "https://i.ibb.co/L0YMjC4/DSC-9846-Poprawione-Szum.jpg"
// ]

const photos = [
    "https://i.ibb.co/LrXwd8m/DJI-20240530124758-0121-D-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/9H5fB7N/DSC-0838-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/gSXqNKP/DSC-6147-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/MZFbNFg/DSC-6660-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/7CGCYVj/DSC-6693-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/HpbRQZD/DSC-7452-Poprawione-Szum-min.jpg",
    "https://i.ibb.co/pbkRCrb/DSC-9388-Poprawione-Szum-min.jpg",

    "https://i.ibb.co/JBW4tGJ/DSC-9846-Poprawione-Szum-min.jpg"
]
export const Plener = () => {
    return (
        <PhotoGallery photos={photos} />
    )
}
