

import React, { useEffect, useState } from 'react';
import './PhotoGallery.scss';
import Modal from 'react-modal';

Modal.setAppElement('#root');

export const PhotoGallery = ({ photos }) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [galleryClass, setGalleryClass] = useState('');

    useEffect(() => {
        if (modalIsOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }


        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [modalIsOpen]);
    useEffect(() => {
        const updateGalleryClass = () => {
            const numImages = photos.length;
            const remainder = numImages % 4;

            if (remainder === 1) {
                setGalleryClass('last-row-one');
            } else if (remainder === 2) {
                setGalleryClass('last-row-two');
            } else {
                setGalleryClass('');
            }
        };

        updateGalleryClass();
        window.addEventListener('resize', updateGalleryClass);

        return () => {
            window.removeEventListener('resize', updateGalleryClass);
        };
    }, [photos]);

    const openModal = (index) => {
        setSelectedIndex(index);
        setModalIsOpen(true);
        document.body.style.overflow = 'hidden';
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedIndex(null);
        document.body.style.overflow = 'auto';
    };

    const goToNextImage = () => {
        if (selectedIndex !== null && selectedIndex < photos.length - 1) {
            setSelectedIndex(selectedIndex + 1);
        }
    };

    const goToPrevImage = () => {
        if (selectedIndex !== null && selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
    };

    return (
        <div>
            <div className={`photoGallery ${galleryClass}`}>
                {photos.map((image, index) => (
                    <img
                        src={image}
                        alt={`Image ${index + 1}`}
                        key={index}
                        onClick={() => openModal(index)}
                    />
                ))}
            </div>

            {selectedIndex !== null && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Image Preview"
                    className="ReactModal__Content"
                    overlayClassName="ReactModal__Overlay"
                >
                    <img src={photos[selectedIndex]} alt={`Full view ${selectedIndex + 1}`} />
                    <div className="modal-controls">
                        <button className="prev" onClick={goToPrevImage} disabled={selectedIndex === 0}>
                            <span className='arrow left'></span>
                        </button>
                        <button className="next" onClick={goToNextImage} disabled={selectedIndex === photos.length - 1}>
                            <span className='arrow right'></span>
                        </button>
                        <button className="close" onClick={closeModal}>X</button>
                    </div>
                </Modal>
            )}
        </div>
    );
};

