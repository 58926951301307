import React, { useState } from 'react'
import './AboutMe.scss'

const photos = [

    "https://i.ibb.co/C7B8kTY/Screenshot-20240519-195544-Facebook-1.jpg",
    "https://i.ibb.co/QjjywRn/Screenshot-20240520-174615-Facebook-1.jpg",
    "https://i.ibb.co/fxRwYZn/Screenshot-20240526-085946-Facebook-1.jpg"
]
const MarzenaPhoto = "https://i.ibb.co/6WMMyWb/df8fde12-4f79-443c-a360-c8f77baa7590.jpg"
export const AboutMe = () => {
    const [imgClass, setImgClass] = useState(false);
    const [currentImg, setCurrentImg] = useState(null);

    const handleClick = (index) => {
        setCurrentImg(photos[index]);
        setImgClass(true);
    };

    const closeImage = () => {
        setImgClass(false);
    };
    return (
        <div className='aboutMe'>
            <h1>O MNIE</h1>
            <div className="rightSide">
                <p>Hej! Mam na imię Marzena. Fotografia jest moją pasją, która od kilku lat wypełnia moje życie. Zdjęcia, które wykonuje staram się, żeby nie były jedynie kolorowymi obrazkami,a furtką do wspomnień, które Państwo przeżyliście. Reportaże mają odzwierciedlać emocje jakie panowały w danej chwili co też w swojej pracy czynie. To co robię poświęcam całe serce, gdyż uważam, że zadowolony klient to szczęśliwa ja.
                </p>
                <p>Jestem pilotem drona, którym rejestruje kadry z wysokości, weekendami pracuje jako fotograf w śląskich klubach, a w tygodniu poza pracą na pełny etat robię sesje plenerowe.
                    Moje fotografie mogliście zobaczyć na oficjalnej stronie miasta Katowice:</p>
                <div className="photos">
                    {photos.map((photo, index) => (
                        <img
                            key={index}
                            src={photo}
                            alt=""
                            onClick={() => handleClick(index)}
                        />
                    ))}
                </div>

                {imgClass && (
                    <div className="fullscreen-overlay" onClick={closeImage}>
                        <img
                            className="fullscreen-img"
                            src={currentImg}
                            alt="full-size"

                        />
                    </div>
                )}
                <p>Do każdego klienta podchodzę w sposób indywidualny i z uwagą wysłuchuje jego potrzeby. Serdecznie zapraszam do współpracy, gdzie razem stworzymy niesamowitą pamiątkę z Waszych szczęśliwych i niezapomnianych chwil.</p>
            </div>
            <div className="leftSide">
                <div>

                    <img src={MarzenaPhoto} alt="" />
                    <div>fot. K. Michalski</div>
                </div>



            </div>

        </div>
    )
}
